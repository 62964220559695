import { AppRoute } from 'kat/lib/routes/types/AppRoute'
import { StateReducers } from 'state/reducers/types/StateReducers'
import { appRoutes } from 'functions/routes/appRoutes'
import { UrlQuery } from '../../functions/routes/types/UrlQuery'
import { getCurrentTaskId } from './getCurrentTaskId'
import { SCRATCH_PAD_KEY } from 'functions/constants/miscContants'
import { LayoutTypesExtra } from 'functions/routes/types/LayoutTypesExtra'

interface Props {
  state: StateReducers
}

interface Return {
  route?: AppRoute<LayoutTypesExtra>
  query?: UrlQuery
  withCurrentQuery?: boolean
}

export const getForwardLocation = ({ state }: Props): Return => {
  const currentLocation = state.currentLocation || undefined
  const isRoutes = currentLocation && currentLocation.isRoutes
  const workspaceId = state.currentWorkspaceId || ''
  const taskId = getCurrentTaskId({ state })
  const workspaceHomeRoute = isRoutes?.workspaceHomeRoute
  const activitiesRoute = isRoutes?.activitiesRoute
  const taskMainRoute = isRoutes?.taskMainRoute

  if (taskMainRoute && taskId === SCRATCH_PAD_KEY) {
    return {
      route: appRoutes.calendarRoute,
      query: {
        workspaceId,
      },
    }
  }

  if (activitiesRoute) {
    return {
      route: appRoutes.taskMainRoute,
      query: {
        taskId: SCRATCH_PAD_KEY,
        workspaceId,
      },
    }
  }

  if (workspaceHomeRoute) {
    return {
      route: appRoutes.activitiesRoute,
      query: {
        workspaceId,
      },
    }
  }

  return {}
}
